import axios, { RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import getAPIService from '@api/service';
import { Logger } from '@utils/logger';

export default {
    namespaced: true,

    state: () => ({}),

    actions: {
        async sendContactUsForm(store: never, data: Record<string, any>): Promise<any> {
            const client = getAPIService(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 60 * 1000,
                validateStatus: (status: number) => status === StatusCodes.OK,
                method: 'POST',
                url: '/svc/v1/partners-portal/contact-us/case',
                data,
            };

            try {
                const response = await client.request(cfg);
                return response.data;
            } catch (error) {
                Logger.error({ error });
                return null;
            }
        },

        async loadFormData(store: never, token: string): Promise<any> {
            const client = axios.create();

            const url = `https://www.acronis.com/en-us/api/v1/lead/${token}`;

            try {
                const response = await client.get(url);
                return response.data;
            } catch (error) {
                Logger.error({ error });
                return null;
            }
        },

        async checkSerialNumber(store: never, data: Record<string, any>): Promise<any> {
            const client = getAPIService(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 60 * 1000,
                validateStatus: (status: number) => status === StatusCodes.OK,
                method: 'POST',
                url: '/svc/v1/grouplogic/entitlement-check',
                withCredentials: false,
                data,
            };

            try {
                const response = await client.request(cfg);
                return response.data;
            } catch (error) {
                Logger.error({ error });
                return null;
            }
        },
    },
};
