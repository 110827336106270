import { StatusCodes } from 'http-status-codes';
import { camelCase, omit } from 'lodash';
import { HttpRejection } from '@model/http/rejection';

type EntityToCheck = {
    entity: { items: any[], httpStatus: number, id: string }
    throwIfEmpty?: Boolean
};

/**
 * Checks for bad statuses and object emptiness (optional).
 */
export function assertHttpErrors(entitiesToCheck: EntityToCheck[]) {
    // clear uninitialized entities
    const list = entitiesToCheck.filter((item) => item.entity?.id);

    // check bad status codes
    const badStatusEntity = list.find((item) => item.entity.httpStatus !== StatusCodes.OK);
    if (badStatusEntity) {
        throw new HttpRejection(
            `Premature rendering stop: invalid http status for '${badStatusEntity.entity.id}'`,
            badStatusEntity.entity.httpStatus,
        );
    }

    // check empty items
    const emptyEntity = list.find((item) => item.throwIfEmpty && !item.entity.items.length);
    if (emptyEntity) {
        throw new HttpRejection(
            `Premature rendering stop: '${emptyEntity.entity.id}' not found`,
            StatusCodes.NOT_FOUND,
        );
    }
}

export function convertToConsumableObjects(responseData: {}[], privateFields: string[]) {
    return responseData.map((x) => {
        const picked = omit(x, privateFields);
        const pairs = Object
            .entries(picked)
            .map((pair: [string, any]) => [camelCase(pair[0]), pair[1]]);
        return Object.fromEntries(pairs);
    });
}
