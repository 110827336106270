// Disclaimer: Sentry recommends to init itself as soon as possible, to be able to capture as many events as it can;
// This script won't run immediately, but it's ok since it will still run entrypoint-client, i.e. as soon as possible inside the main app
// NB: this script is client-only
import * as Sentry from '@sentry/browser';

// Set this to true if you need to test some changes on the local instance
const SENTRY_FORCE_ENABLE = false;

const InitValidator = {
    itIsProductionDomain() {
        const host = window.location.hostname;
        return host.endsWith('.acronis.com') && !host.includes('.gcp.acronis.com') && !host.includes('webdev.acronis.com');
    },
    isEnabled() {
        return SENTRY_FORCE_ENABLE || this.itIsProductionDomain();
    },
};

const SOURCE_BLACKLIST = [
    /extensions\//i,
    /chrome-extension:/i,
    /chrome:\/\//i,
    /webkit-masked-url:\/\//i,
    /^https:\/\/cdn\.cookielaw\.org\//i,
    /^https:\/\/munchkin.marketo.net\//i,
    /^https:\/\/www.googletagmanager.com\//i,
    /^https:\/\/script.hotjar.com\//i,
    /ping[.]min[.]js/i,
    /gtm[.]js/i,
    /6si[.]min[.]js/i,
    /clarity[.]js/i,
    /fbevents[.]js/i,
];

const ERRORS_BLACKLIST = [
    // Sentry recommended rules
    'top.GLOBALS',
    'originalCreateNotification',
    'canvas.contentDocument',
    'fb_xd_fragment',
    'conduitPage',
    // Well-known 3rd party integrations
    'MktoForms2',
    'google_tag_manager',
    'unsafe-eval',
    'Munchkin',
    // Custom rules
    /ResizeObserver/i, // probably an error from a vendor lib. Should not affect users
    'The quota has been exceeded', // inaccessible localstorage, usually fired because of OneTrust wrapping
    /Loading chunk \d+ failed/i, // flacky kubectl rollout
    /Failed to fetch/i, // fetch API errors (excluded because without a context it's just a noise)
    /^TypeError: Load failed$/i, // looks like the same as above, but for iOS Safari
    /NetworkError/i, // we can't do anything with users connection quality
    /video-only background media was paused/i, // has no value and besides a completely normal behavior
    /The play\(\) request/i, // same
    /reCAPTCHA placeholder element must be an element or id/i, // judging by breadcrumbs it happens when a user opens a form without OneTrust consent given
    /Object Not Found Matching Id/i, // same
    /Avoided redundant navigation/i, // should not be an error at all
    /evaluating 'r\["@[^"]+"\].toLowerCase'/i, // For fucks sake Safari tries to parse and process ld+json schema, but of course does it wrong
    /NotAllowedError/i, // restrictive environments, not our fault
    /SecurityError/i, // same
    /Non-Error promise rejection captured with value: Timeout/i, // no idea what's this but anyway we don't need to ingest timeout errors
    /CustomEvent/i, // no idea, non-reproducible, mostly Safari
    /\s+fbq/i, // too many and totally useless
    /property 'createElement' is a read-only and non-configurable/i, // microfrontend issues on Solutions. Can be removed after the rework
];

export const initSentry = (context: any) => {
    if (!InitValidator.isEnabled()) {
        return;
    }

    Sentry.init({
        debug: SENTRY_FORCE_ENABLE,
        release: `head-site-main@browser@${context.public.appVersion}`,
        dsn: 'https://cd987e00d8a4970ecf4d96f763fa9798@o394472.ingest.sentry.io/6232185',
        maxBreadcrumbs: 10,
        attachStacktrace: true,
        autoSessionTracking: false,
        denyUrls: SOURCE_BLACKLIST,
        ignoreErrors: ERRORS_BLACKLIST,
    });
};
