import { RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ActionContext, Store } from 'vuex';
import getAPIService from '@api/service';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { Logger } from '@utils/logger';

interface Feed {
    pressReleases: Record<string, string>[];
}

interface S {
    feed: Feed;
}

export default {
    namespaced: true,

    state: (): S => ({
        feed: {
            pressReleases: [],
        },
    }),

    actions: {
        async getFeed(store: ActionContext<S, any>, args: { locale: string }) {
            const client = getAPIService(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 5000,
                method: 'GET',
            };

            try {
                cfg.url = `/svc/v1/acronis-publications/feed/${args.locale}`;
                cfg.validateStatus = (status: number) => [StatusCodes.OK, StatusCodes.NOT_FOUND].includes(status);
                const response = await client.request(cfg);
                if (response.status === StatusCodes.OK) {
                    store.commit('setFeed', response.data.data);
                    return;
                }
            } catch (error) {
                Logger.error({ error });
                return;
            }

            // Fallback to default locale if localized is not supported
            try {
                cfg.url = `/svc/v1/acronis-publications/feed/${LOCALE_DEFAULT}`;
                cfg.validateStatus = (status: number) => status === StatusCodes.OK;
                const response = await client.request(cfg);
                store.commit('setFeed', response.data.data);
            } catch (error) {
                Logger.error({ error });
            }
        },
    },

    mutations: {
        setFeed(state: Store<S>, feed: Feed) {
            state.feed = feed;
        },
    },
};
