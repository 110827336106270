import { RawAxiosRequestConfig, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ActionContext, Store } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import getAPIProduct from '@api/product';
import getAPIService from '@api/service';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { HttpRejection } from '@model/http/rejection';
import { Logger } from '@utils/logger';

export default {
    namespaced: true,

    state: () => ({
        pricingMethod: 1,
        currency: null,
        minimumCommit: null,
        discountAfter: null,
        group: null,
        requirements: {
            workloads: 20,
            storage: 200,
        },
        unitPrices: [],
        currentPriceList: {
            id: null,
            name: null,
        },
        priceLists: [],
        prices: null,
        items: {},
    }),

    actions: {
        async createLeadRequest(store: never, data: Record<string, any>) {
            const client = getAPIService(this.$context);

            const cfg: RawAxiosRequestConfig = {
                method: 'POST',
                url: '/svc/v1/marketing/forms/leads',
                timeout: 60 * 1000,
                validateStatus: (status: number) => status === 200,
                data,
            };

            const response = await client.request(cfg);
            return response;
        },

        async updateLeadRequest(store: never, data: Record<string, any>) {
            const client = getAPIService(this.$context);

            const cfg: RawAxiosRequestConfig = {
                method: 'PUT',
                url: `/svc/v1/marketing/forms/leads/${data.osauid}`,
                timeout: 60 * 1000,
                validateStatus: (status: number) => status === 200,
                data,
            };

            const response = await client.request(cfg);
            return response;
        },

        getSyncedData(store, args) {
            const { key, locale } = args;
            const data = this.$context.Synced.vroiData.query(key, locale, LOCALE_DEFAULT);
            store.commit('setSyncedData', { key, data });
        },

        async getEntity(store: ActionContext<any, any>, args: { request: Storage, httpRejectEmptyState: Boolean }) {
            const request = args.request;
            const client = getAPIProduct(this.$context);

            const opts: RawAxiosRequestConfig = {
                validateStatus: (status: number) => status === StatusCodes.OK,
                timeout: process.env.VUE_ENV === 'client' ? 30000 : 6000,
                method: 'GET',
                url: request.entityPath,
                params: request.params,
            };

            try {
                const response = await client.request(opts);
                store.commit('setEntity', { request, response });
            } catch (error) {
                Logger.error({ error });
                const httpStatus = error.status || StatusCodes.BAD_GATEWAY;
                const message = error.message || 'Premature rendering stop';
                throw new HttpRejection(message, httpStatus);
            }
        },

        async getPriceLists(store: ActionContext<any, any>) {
            const queryBuilder: ProductAPIQueryBuilder = new ProductAPIQueryBuilder('priceLists')
                .setEntityPath('/api/core/price-lists/')
                .setPaginate(1, 50);
            const request = queryBuilder.toObject();

            await store.dispatch('getEntity', { request });
        },

        async getPricesForPriceBook(store: ActionContext<any, any>, productCodes: string) {
            const currentPriceListId = store.state.currentPriceList.id;

            const priceBooksRequest = new ProductAPIQueryBuilder('unitPrices')
                .setEntityPath('/api/core/price-lists/prices')
                .addMatchesAll('price_list_id', '=', currentPriceListId)
                .addMatchesAll('product_code', 'in', productCodes)
                .setPaginate(1, 50)
                .toObject();

            await store.dispatch('getEntity', { request: priceBooksRequest });
        },
    },
    mutations: {
        setEntity(state: Store<any>, args: { request: Storage, response: AxiosResponse }) {
            state[args.request.id] = args.response.data.data;
        },
        setSyncedData(state, { key, data }) {
            state.items[key] = data;
        },
        setMinimumCommit(state, payload) {
            state.minimumCommit = payload;
        },
        setDiscountAfter(state, payload) {
            state.discountAfter = payload;
        },
        setCurrency(state, payload) {
            state.currency = payload;
        },
        setPricingMethod(state, payload) {
            state.pricingMethod = payload;
        },
        setCurrentPriceList(state, payload) {
            state.currentPriceList.id = payload.id;
            state.currentPriceList.name = payload.name;
        },
        setGroup(state, payload) {
            state.group = payload;
        },
        setStorageOptions(state, payload) {
            state.storageOptions = payload;
        },
    },
};
