import * as lodash from 'lodash';

type CompareOperator = '>' | '>=' | '<' | '<=' | '=' | '<>' | 'like' | 'in' | 'not in' | 'null';
type SortingDirection = 'desc' | 'asc';

class StorageOutput {
    type: 'only' | 'except';

    items: string[];
}

class StoragePaginate {
    'page-number': number;

    'page-size': number;

    'skip-items': number | undefined;
}

type StorageParamsExplicit = {
    locales: string[];
    sort: Record<string, SortingDirection>;
    date: Record<string, Record<string, string>>;
    'matches-all': [string, CompareOperator, string][];
    'matches-any': [string, CompareOperator, string][];
    output: StorageOutput;
    paginate: StoragePaginate;
}

// TODO: rework this to remove union type
export type StorageParams = Record<string, any> & StorageParamsExplicit;

export class Storage {
    id: string;

    params: StorageParams;

    entityPath?: string;

    addMore: boolean;
}

export class ProductAPIQueryBuilder {
    private storage: Storage;

    constructor(id: string) {
        this.storage = {
            id,
            params: {} as StorageParams,
            addMore: false,
        };
    }

    clone(id: string): this {
        const copy = new ProductAPIQueryBuilder(id);
        copy.storage = lodash.cloneDeep(this.storage);
        copy.storage.id = id;
        return copy as this;
    }

    mergeParams(incoming: StorageParams): this {
        this.storage.params = lodash.merge({}, this.storage.params, incoming);
        return this;
    }

    setEntityPath(path: string): this {
        this.storage.entityPath = path;
        return this;
    }

    setLocales(value: string[]): this {
        this.storage.params.locales = value;
        return this;
    }

    addSort(field: string, direction: SortingDirection): this {
        this.storage.params.sort = this.storage.params.sort || {};
        this.storage.params.sort[field] = direction;
        return this;
    }

    addDateCompare(field: string, dateField: string, value: string): this {
        this.storage.params.date = this.storage.params.date || {};
        this.storage.params.date[field] = this.storage.params.date[field] || {};

        this.storage.params.date[field]![dateField] = value;
        return this;
    }

    addMatchesAll(field: string, operator: CompareOperator, value?: string): this {
        if (!value) return this;

        this.storage.params['matches-all'] = this.storage.params['matches-all'] || [];
        this.storage.params['matches-all'].push([field, operator, value]);
        return this;
    }

    addMatchesAny(field: string, operator: CompareOperator, value?: string): this {
        if (!value) return this;

        this.storage.params['matches-any'] = this.storage.params['matches-any'] || [];
        this.storage.params['matches-any'].push([field, operator, value]);
        return this;
    }

    // NB: setOutputOnly and setOutputExcept are mutually exclusive
    setOutputOnly(fields: string[]): this {
        if (this.storage.params.output?.type === 'except') {
            throw new Error('Can\'t use setOutputOnly and setOutputExcept at the same query');
        }

        this.storage.params.output = {
            type: 'only',
            items: fields,
        };

        return this;
    }

    setOutputExcept(fields: string[]): this {
        if (this.storage.params.output?.type === 'only') {
            throw new Error('Can\'t use setOutputOnly and setOutputExcept at the same query');
        }

        this.storage.params.output = {
            type: 'except',
            items: fields,
        };

        return this;
    }

    setCustomParam(name: string, value: any): this {
        if (name && value) {
            this.storage.params[name] = value;
        }

        return this;
    }

    setPaginate(page: number, perPage: number, skip?: number): this {
        const pg = new StoragePaginate();
        pg['page-number'] = page;
        pg['page-size'] = perPage;
        pg['skip-items'] = skip && skip > 0 ? skip : undefined;

        this.storage.params.paginate = pg;

        return this;
    }

    setAddMoreFlag(flag: boolean): this {
        this.storage.addMore = Boolean(flag);
        return this;
    }

    toObject(): Storage {
        return this.storage;
    }
}
