import { RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import getAPIService from '@api/service';
import { ServiceAPIError } from '@model/http/service-api-error';
import { Logger } from '@utils/logger';

export default {
    namespaced: true,

    state: () => ({}),

    actions: {
        async doExpressSignupRequest(store: never, data: Record<string, any>) {
            const client = getAPIService(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 30 * 1000,
                validateStatus: () => true,
                method: 'POST',
                url: '/svc/v1/marketing/forms/leads',
                data,
            };

            const response = await client.request(cfg);

            if (response.status !== StatusCodes.OK) {
                Logger.error({ error: response });
                throw ServiceAPIError.FromAxiosResponse(response);
            }

            return response.data;
        },
    },
};
