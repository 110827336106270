import axios from 'axios';
import { Logger } from '@utils/logger';

// NB: always use production instance here; staging can't detect your IP because it's internal
const API_ENDPOINT = 'https://websiteapi.acronis.com/geo/location/v1/ip/geolocation';

export default {
    namespaced: true,

    state: () => ({
        location: null,
        showLocaleSelector: false,
    }),

    actions: {
        async getGeoLocation(store) {
            try {
                const response = await axios.get(API_ENDPOINT);

                if (!response.data) {
                    const err = new Error('Error: getGeoLocation');
                    throw err;
                }

                store.commit('setGeolocation', response.data);
            } catch (error) {
                Logger.error({ error });
            }
        },
    },

    mutations: {
        setGeolocation(state, location) {
            state.location = location;
        },
    },
};
