import monochromeIconsSpriteURL from '@dist/svg-sprite/monochrome.sprite.svg';
import multicolorIconsSpriteURL from '@dist/svg-sprite/multicolor.sprite.svg';
import productIconsSpriteURL from '@dist/svg-sprite/product-icons.sprite.svg';
import productUikitSpriteURL from '@dist/svg-sprite/product-uikit.sprite.svg';
import webIconsSpriteURL from '@dist/svg-sprite/web.sprite.svg';
import { sendBrowserMetrics } from '@utils/browser-metrics.ts';
import { initSentry } from '@utils/browser-sentry.ts';
import { Logger } from '@utils/logger.ts';
import { AppFactory } from './app/app-factory.ts';

/* eslint-disable no-underscore-dangle */
const context = (window.__INITIAL_STATE__ || {}).context;

const { app, store, router } = AppFactory(context);

router.onReady(() => {
    if (window.__INITIAL_STATE__) {
        store.replaceState(window.__INITIAL_STATE__);
    }

    app.$mount('#root');
});
/* eslint-enable */

const injectSprite = async (id, path) => {
    try {
        const resp = await fetch(path);
        const svg = await resp.text();

        const div = document.createElement('div');
        div.id = id;
        div.innerHTML = svg;
        div.style = 'position: absolute; width: 0; height: 0; overflow: hidden;';
        document.body.insertBefore(div, document.body.childNodes[0]);
    } catch (error) {
        Logger.error({ error });
    }
};

injectSprite('product-uikit', productUikitSpriteURL);
injectSprite('product-icons', productIconsSpriteURL);
injectSprite('web-icons', webIconsSpriteURL);
injectSprite('multicolor-icons', multicolorIconsSpriteURL);
injectSprite('monochrome-icons', monochromeIconsSpriteURL);

sendBrowserMetrics(context);
initSentry(context);
