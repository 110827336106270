export const STORE_SLICE_NAME = 's-preset-form-resource-center';

export const RC_RESOURCE_FIELDS_SUBSET_SHORT = [
    'id',
    'slug',
    'title',
    'published_at',
    'cover_image_id',
    'seo_title',
    'type',
    'is_gated',
];

export const RC_MAX_ITEMS_PEG_PAGE = 50;
export const RC_MAX_ITEMS_PEG_SEARCH_PAGE = 15;

export const RC_MAX_FEATURED_RESOURCES = 4;

export const RC_MAX_CASE_STUDY_RESOURCES = 2;
export const RC_CASE_STUDY_TYPE_ID = 5;
export const RC_CASE_STUDY_SLUG = 'case-studies';

export const RC_ROOT_URL = '/resource-center';

export const RC_ROOT_BREADCRUMB = {
    text: 'Resource Center',
    to: '/resource-center/',
    glyph: 'home',
    glyphPosition: 'left',
};

export const RC_EMPTY_BREADCRUMB = {
    text: 'Current resource',
    to: '',
};

export const RC_SEARCH_PAGE_BREADCRUMBS = [
    {
        text: 'Resource Center',
        to: RC_ROOT_URL,
        glyph: 'home',
        glyphPosition: 'left',
    },
    {
        sublinks: false,
    },
    {
        text: 'Search',
        to: '',
    },
];

export const RC_GATED_FORM_DEFAULTS = {
    form_id: 'gated-resource-form',
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    phone: '',
    country: '',
    state: '',
    postal_code: '',
};

export const RC_RESOURCE_STORAGE_UNLOCKED_KEY = 'gated_resource_state_';
export const RC_GATED_STATE_LOCKED = 'locked';
export const RC_GATED_STATE_SUBMITTED = 'submitted';
export const RC_GATED_STATE_UNLOCKED = 'unlocked';

export const RC_CASE_STUDY_SNIPPETS = new Set(['caseStudyLeftLogo', 'caseStudyLeftBlock']);

export const RC_LOCALES_FOR_SERP_REDIRECT = new Set(['it-it', 'zh-cn', 'ko-kr', 'zh-tw', 'pl-pl']);

export const RC_LOCALES_FOR_US_REDIRECT = new Set(['pt-br']);

export const RC_LOCALES_CPC_AVAILABLE = new Set(['en-eu', 'en-gb', 'en-sg', 'en-us']);

export const RC_LOCALES_BLOG_AVAILABLE = new Set([
    'ja-jp',
    'pl-pl',
    'en-eu',
    'en-gb',
    'en-sg',
    'en-us',
    'fr-fr',
    'es-es',
    'es-mx',
    'de-de',
    'ko-kr',
    'it-it',
]);

export const RC_CPC_NEWS_FIELDS_SUBSET = [
    'id',
    'slug',
    'image_id',
    'section_id',
    'title',
    'description',
    'published_at',
    'time_to_read',
];
export const RC_CPC_NEWS_AMOUNT = 4;
export const RC_BLOG_NEWS_FIELDS_SUBSET = [
    'id',
    'slug',
    'image_id',
    'section_id',
    'title',
    'description',
    'published_at',
    'time_to_read',
];
export const RC_BLOG_NEWS_AMOUNT = 4;
export const RC_SPECIFIC_RESOURCES_AMOUNT = 4;
export const KEYCODE_ENTER = 13;
export const RC_LOCAL_STORAGE_SEARCH_FILTERS = 'resourceSearchFilters';
export const GA_SOCIAL_EVENT = {
    category: 'Interactions',
    context: 'click',
    action: 'link',
};
export const RC_MAX_RELEASES_PEG_PAGE = 8;
