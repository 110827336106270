import { ActionContext } from 'vuex';

export default {
    namespaced: true,

    state: () => ({
        courses: [],
    }),

    actions: {
        getCourses(store: ActionContext<any, any>) {
            const courses = this.$context.Synced.academy.getAll();
            store.commit('setCourses', courses);
        },
    },

    mutations: {
        setCourses(state: any, courses: any) {
            state.courses = courses;
        },
    },
};
