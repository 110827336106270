import axios, { AxiosInstance, RawAxiosRequestConfig } from 'axios';
import { SENSE_API_KEY } from '@model/const/api-keys';

const getAPI6sense = (_context: any): AxiosInstance => {
    const defaults: RawAxiosRequestConfig = {
        timeout: 10000,
        baseURL: 'https://epsilon.6sense.com',
        withCredentials: true,
        headers: {
            Authorization: `Token ${SENSE_API_KEY}`,
        },
    };

    const instance = axios.create(defaults);
    // can wrap plugins here
    return instance;
};

export default getAPI6sense;
