import { RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import getAPIAccount from '@api/account';
import { Logger } from '@utils/logger';

export default {
    namespaced: true,

    state: () => ({
        data: null,
    }),

    actions: {
        async get(store) {
            try {
                const client = getAPIAccount(this.$context);

                const cfg: RawAxiosRequestConfig = {
                    validateStatus: (status: number) => [StatusCodes.OK, StatusCodes.UNAUTHORIZED].includes(status),
                    method: 'GET',
                    url: '/v2/account',
                };

                const resp = await client.request(cfg);
                store.commit('setUser', resp.data.account);
            } catch (error) {
                Logger.error({ error });
            }
        },

        async logout(store) {
            try {
                const client = getAPIAccount(this.$context);

                const cfg: RawAxiosRequestConfig = {
                    validateStatus: (status: number) => [StatusCodes.NO_CONTENT].includes(status),
                    method: 'POST',
                    url: '/v2/auth/logout',
                };

                await client.request(cfg);
                store.commit('setUser', null);
            } catch (error) {
                Logger.error({ error });
            }
        },
    },

    mutations: {
        setUser(state, user) {
            state.data = user;
        },
    },
};
