/*
 * Used to find correct cardId based on currency and price combination
*/

export const tiersMap = {
    258666: {
        tier: 1,
        USD: 250,
        GBP: 175,
        EUR: 250,
        CAD: 350,
        AUD: 350,
        JPY: 30000,
    },
    258667: {
        tier: 2,
        USD: 500,
        GBP: 350,
        EUR: 500,
        CAD: 700,
        AUD: 750,
        JPY: 60000,
    },
    258668: {
        tier: 3,
        USD: 1000,
        GBP: 750,
        EUR: 1000,
        CAD: 1500,
        AUD: 1500,
        JPY: 125000,
    },
};
