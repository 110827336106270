import { RawAxiosRequestConfig, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ActionContext, Store } from 'vuex';
import { ProductAPIQueryBuilder, Storage } from '@api/builders/product';
import getAPIProduct from '@api/product';
import { SECTION_SLUG_BY_ID, SECTION_ID_BLOG, CARD_FIELDS_SUBSET } from '@model/const/blog';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { Logger } from '@utils/logger';

const postCustomMapper = (post) => ({
    ...post,
    pathname: `/${SECTION_SLUG_BY_ID[post.section_id]}/posts/${post.slug}/`,
});

export default {
    namespaced: true,

    state: () => {
        // WARN: empty state shape can cause reactivity issues on mutation
        const shape = () => ({
            id: '',
            httpStatus: 0,
            locale: '',
            pagination: {},
            items: [],
        });

        return {
            topPost: shape(),
            latestPost: shape(),
            latestCards: shape(),
            CPCNews: shape(),
            spotlightedCPC: shape(),
            featuredCPC: shape(),
            featuredBlog: shape(),
            popularBlog: shape(),
            category: shape(),
            categories: shape(),
            post: shape(),
            tag: shape(),
            authors: shape(),
            author: shape(),
            news: shape(),
            cards: shape(),
            previousPost: shape(),
            nextPost: shape(),
            morePosts: shape(),
            tags: shape(),
            assets: shape(),
            audiences: shape(),
            cta: shape(),
            ctaData: shape(),
            postTranslationsList: shape(),
            categoryTranslationsList: shape(),
            sectionTranslationsList: shape(),
            authorTranslationsList: shape(),
            tagTranslationsList: shape(),
            // ==== Custom actions
            latestBlogPosts: shape(),
            insightsNews: shape(),
        };
    },

    actions: {
        async getEntity(store: ActionContext<any, any>, args: { request: Storage }) {
            const request = args.request;

            const client = getAPIProduct(this.$context);

            const opts: RawAxiosRequestConfig = {
                validateStatus: (status: number) => status === StatusCodes.OK,
                timeout: process.env.VUE_ENV === 'client' ? 30000 : 6000,
                method: 'GET',
                url: request.entityPath,
                params: request.params,
            };

            try {
                const response = await client.request(opts);
                store.commit('setEntity', { request, response });
            } catch (error) {
                Logger.error({ error });
                store.commit('setHttpStatus', { request, status: error.status || StatusCodes.GATEWAY_TIMEOUT });
            }
        },

        deleteEntity(store: ActionContext<any, any>, id: string) {
            store.commit('deleteEntity', id);
        },

        async sendViewBeacon(_: never, args: { request: Storage }) {
            const request = args.request;

            const client = getAPIProduct(this.$context);

            const opts: RawAxiosRequestConfig = {
                validateStatus: (status: number) => status === StatusCodes.CREATED,
                timeout: 30000,
                method: 'POST',
                url: '/api/blog/posts/views/',
                params: request.params,
            };

            // This is a fire-n-forget action
            try {
                await client.request(opts);
            } catch (error) {
                Logger.error({ error });
            }
        },

        // ==== Custom actions "facade" for some slices; require to build an actual request to the API so it can't be in @core
        // s-blog-posts, homepage spotlight, company, etc
        async getLatestBlogPosts(store: ActionContext<any, any>, options: { locale?: string, limit: number, productID?: number }) {
            const stateID = 'latestBlogPosts';
            const locale = options.locale || LOCALE_DEFAULT;

            const requestTemplate = new ProductAPIQueryBuilder(stateID)
                .setEntityPath('/api/blog/posts/')
                .addMatchesAll('section_id', '=', SECTION_ID_BLOG.toString())
                .addMatchesAll('is_news', '<>', '1')
                .setCustomParam('has', options.productID ? { products: [options.productID] } : null)
                .setCustomParam('process-macros', '1')
                .setOutputOnly(CARD_FIELDS_SUBSET)
                .addSort('translation.published_at', 'desc')
                .addSort('id', 'desc')
                .setPaginate(1, options.limit);

            // trying to get a localized version
            const localizedReq = requestTemplate
                .clone(stateID)
                .setLocales([locale])
                .toObject();
            await store.dispatch('getEntity', { request: localizedReq });

            if (store.state[stateID].items?.length || locale === LOCALE_DEFAULT) return;

            // fall back to default locale
            const fallbackReq = requestTemplate
                .clone(stateID)
                .setLocales([LOCALE_DEFAULT])
                .toObject();
            await store.dispatch('getEntity', { request: fallbackReq });
        },
    },

    mutations: {
        setHttpStatus(state: Store<any>, args: { request: Storage, status: number }) {
            state[args.request.id] = state[args.request.id] || {};

            state[args.request.id].id = args.request.id;
            state[args.request.id].httpStatus = args.status;
            state[args.request.id].pagination = state[args.request.id].pagination || {};
            state[args.request.id].pagination.loading = false;
        },

        setEntity(state: Store<any>, args: { request: Storage, response: AxiosResponse }) {
            state[args.request.id] = state[args.request.id] || {};

            state[args.request.id].locale = args.request.params?.locales?.[0] || LOCALE_DEFAULT;

            state[args.request.id].id = args.request.id;
            state[args.request.id].httpStatus = args.response.status;
            state[args.request.id].pagination = args.response.data.pagination;
            state[args.request.id].pagination.loading = false;

            let items = args.request.addMore
                ? [...state[args.request.id].items, ...args.response.data.data]
                : args.response.data.data;

            // Desperate times call for desperate measures
            // TODO: can we do it more elegant?
            if (args.request.entityPath?.endsWith('/blog/posts/')) {
                items = items.map(postCustomMapper);
            }

            state[args.request.id].items = items;
        },

        deleteEntity(state: Store<any>, id: string) {
            state[id].items = [];
        },
    },
};
