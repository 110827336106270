import axios, { AxiosInstance, RawAxiosRequestConfig } from 'axios';

const getAPIAccount = (_context: any): AxiosInstance => {
    const defaults: RawAxiosRequestConfig = {
        timeout: 10000,
        baseURL: 'https://account.acronis.com', // should it be configurable?
        withCredentials: true,
    };

    const instance = axios.create(defaults);
    // can wrap plugins here
    return instance;
};

export default getAPIAccount;
