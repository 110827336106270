export default {
    namespaced: true,

    state: () => ({
        items: [],
    }),

    actions: {
        getCountries(store) {
            const countries = this.$context.Synced.countries.getAll();
            store.commit('setCountries', countries);
        },
    },

    mutations: {
        setCountries(state, countries) {
            state.items = countries;
        },
    },
};
