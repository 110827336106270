import { AxiosInstance, InternalAxiosRequestConfig, ParamsSerializerOptions } from 'axios';
import qs from 'qs';
import { Logger } from '@utils/logger';

const childLogger = Logger.child({ name: 'Axios' });

export const WrapAxiosWithRequestLogger = (axios: AxiosInstance): AxiosInstance => {
    if (!Logger.isLevelEnabled || !Logger.isLevelEnabled('debug')) return axios;

    const loggerFn = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
        let r = `${config.method.toUpperCase()} ${config.baseURL}${config.url}`;
        if (config.params) {
            const serializer = config.paramsSerializer as ParamsSerializerOptions;
            const serializeFn = serializer?.serialize || qs.stringify;
            r += `?${serializeFn(config.params)}`;
        }
        childLogger.debug({ r }, 'Request sent');
        return config;
    };

    axios.interceptors.request.use(loggerFn);

    return axios;
};
