import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('config', ['$config']),

        getSessionUTM() {
            if (process.env.VUE_ENV === 'server') return [];
            return ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term']
                .filter((key) => window.sessionStorage.getItem(key))
                .map((key) => ({ key, val: window.sessionStorage.getItem(key) }));
        },
    },

    methods: {
        shouldInjectUTM(hostname) {
            const hosts = ['promo.acronis.com'];
            hosts.push(this.$config.domain.includes('events') ? 'acronis.com' : 'acronis.events');
            return hosts.includes(hostname);
        },

        setQueryString(link) {
            const parsed = new URL(link, `https://${this.$config.domain}`);
            if (!this.shouldInjectUTM(parsed.hostname)) return '';
            this.getSessionUTM.forEach((item) => parsed.searchParams.set(item.key, item.val));
            return parsed.toString();
        },
    },
};
