import { RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ActionContext, Store } from 'vuex';
import { Storage } from '@api/builders/product';
import getAPIProduct from '@api/product';
import { Logger } from '@utils/logger';

const VWO_STATE_CHECK_INTERVAL_MS = 50;
const VWO_MAX_ATTEMPTS = 30;

export default {
    namespaced: true,

    state: () => ({
        httpStatus: 0,
        page: null,
        audiences: null,
        types: null,
        loading: false,
        vwoTestsEnabled: false,
        vwoCheckInterval: 0,
        vwoCheckAttempts: 0,
    }),

    actions: {
        async getEntity(store: ActionContext<any, any>, args: { request: Storage }) {
            const request = args.request;

            const client = getAPIProduct(this.$context);

            const opts: RawAxiosRequestConfig = {
                validateStatus: (status: number) => status === StatusCodes.OK,
                timeout: process.env.VUE_ENV === 'client' ? 30000 : 6000,
                method: 'GET',
                url: request.entityPath,
                params: request.params,
            };

            try {
                const response = await client.request(opts);
                store.commit('setEntity', { request, response });
            } catch (error) {
                Logger.error({ error });
                store.commit('setHttpStatus', { request, status: error.status || StatusCodes.GATEWAY_TIMEOUT });
            }
        },
        async getPage(store: ActionContext<any, any>, args: { request: Storage }) {
            const request = args.request;

            const client = getAPIProduct(this.$context);

            const opts: RawAxiosRequestConfig = {
                validateStatus: (status: number) => status === StatusCodes.OK,
                timeout: process.env.VUE_ENV === 'client' ? 30000 : 6000,
                method: 'GET',
                url: request.entityPath,
                params: request.params,
            };

            try {
                const { data, status } = await client.request(opts);

                if (!data?.data) {
                    throw new Error('Invalid page data');
                }

                let pageData: {};
                // normal case
                if (Array.isArray(data.data)) {
                    pageData = data.data[0];
                } else { // cached preview case
                    try {
                        pageData = JSON.parse(data.data);
                    } catch {
                        throw new Error('Invalid page data');
                    }
                }

                store.commit('setHttpStatus', status);
                store.commit('setPage', pageData);
            } catch (error) {
                Logger.error({ error });
                store.commit('setHttpStatus', { request, status: error.status || StatusCodes.GATEWAY_TIMEOUT });
            }
        },
        setLoading(store: ActionContext<string, any>, payload: Boolean) {
            store.commit('changeLoading', payload);
        },
    },

    mutations: {
        setHttpStatus(state: any, status: number) {
            state.httpStatus = status;
        },
        setPage(state: any, newPageData: object) {
            const processedPageData: any = { ...newPageData };
            const propsToParse = ['meta', 'body', 'schema', 'settings'];

            propsToParse.forEach((propName) => {
                if (typeof processedPageData[propName] === 'string') {
                    try {
                        processedPageData[propName] = JSON.parse(processedPageData[propName]);
                    } catch {
                        processedPageData[propName] = null;
                    }
                }
            });

            state.page = processedPageData;
        },

        setVwoTests(state, value = false) {
            state.vwoTestsEnabled = value;
        },

        setVwoCheckInterval(state: any, interval: number = VWO_STATE_CHECK_INTERVAL_MS) {
            state.vwoCheckInterval = interval;
        },

        setVwoCheckAttempts(state: any, attempts: number = VWO_MAX_ATTEMPTS) {
            state.vwoCheckAttempts = attempts;
        },

        changeLoading(state, payload: Boolean) {
            state.loading = payload;
        },

        setEntity(state: Store<any>, args: { request: Storage, response: AxiosResponse }) {
            state[args.request.id] = state[args.request.id] || {};

            state[args.request.id].id = args.request.id;
            state[args.request.id].httpStatus = args.response.status;

            if (args.response.data?.pagination) {
                state[args.request.id].pagination = args.response.data.pagination;
                state[args.request.id].pagination.loading = false;
            }

            if (Array.isArray(args.response.data.data)) {
                state[args.request.id].items = args.request.addMore
                    ? [...state[args.request.id].items, ...args.response.data.data]
                    : args.response.data.data;
            } else {
                state[args.request.id].data = args.response.data.data;
            }
        },
    },
};
