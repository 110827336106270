export const SECTION_ID_BLOG = 1;
export const SECTION_ID_CPC = 2;

export const SECTION_ID_BY_SLUG = {
    blog: SECTION_ID_BLOG,
    'cyber-protection-center': SECTION_ID_CPC,
};

export const SECTION_SLUG_BY_ID = Object.fromEntries(Object.entries(SECTION_ID_BY_SLUG).map((x) => x.reverse()));

export const CARD_FIELDS_SUBSET = [
    'id',
    'slug',
    'image_id',
    'section_id',
    'title',
    'description',
    'published_at',
    'time_to_read',
];

export const NEWS_NUMBER_ON_PAGE = 3;
