import { AxiosResponse, RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import qs from 'qs';
import { ActionContext, Store } from 'vuex';
import { ProductAPIQueryBuilder, Storage } from '@api/builders/product';
import getAPIProduct from '@api/product';
import { PR_MAX_RELEASES_PEG_PAGE } from '@model/const/press-releases';
import { HttpRejection } from '@model/http/rejection';
import { getAvailableLocales } from '@utils/locales';
import { Logger } from '@utils/logger';

export default {
    namespaced: true,

    state: () => {
        const shape = () => ({
            httpStatus: 0,
            pagination: {},
            items: [],
            data: null,
        });

        return {
            pressReleases: shape(),
            pressRelease: shape(),
            pressReleaseAuthor: shape(),
            availableLocales: shape(),
        };
    },

    getters: {
        getAvailableLocales: (state: any) => (currentLocale: string) => {
            type LocaleItem = { locale: string, published_at: string };
            const locales = getAvailableLocales(state.availableLocales.items, currentLocale);

            return state.availableLocales.items
                .filter((item: LocaleItem) => locales.includes(item.locale))
                .filter(Boolean)
                .map((item: LocaleItem) => ({
                    locale: item.locale,
                    year: parseInt(item.published_at, 10),
                }));
        },
    },

    actions: {
        async getEntity(store: ActionContext<any, any>, args: { request: Storage, httpRejectEmptyState: Boolean }) {
            const request = args.request;
            const httpRejectEmptyState = args.httpRejectEmptyState === undefined ? true : args.httpRejectEmptyState;

            const client = getAPIProduct(this.$context);

            const opts: RawAxiosRequestConfig = {
                validateStatus: (status: number) => status === StatusCodes.OK,
                timeout: process.env.VUE_ENV === 'client' ? 30000 : 6000,
                maxRedirects: 0,
                paramsSerializer: {
                    serialize: (params) => qs.stringify(params, { encodeValuesOnly: true }),
                },
                method: 'GET',
                url: request.entityPath,
                params: request.params,
            };

            try {
                const response = await client.request(opts);
                store.commit('setEntity', { request, response });

                if (httpRejectEmptyState && !store.state[request.id]?.items?.length) {
                    throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);
                }
            } catch (error) {
                Logger.error({ error });

                const httpStatus = error.status || error.httpStatusCode || error.response?.status || StatusCodes.GATEWAY_TIMEOUT;
                const message = error.message || 'Premature rendering stop';

                store.commit('setHttpStatus', { request, status: httpStatus });

                throw new HttpRejection(message, httpStatus);
            }
        },

        async applyHomeFilters(store: ActionContext<any, any>, { filters, locale }) {
            const page = filters.page || 1;
            const queryBuilder: ProductAPIQueryBuilder = new ProductAPIQueryBuilder('pressReleases')
                .setEntityPath('/api/press-releases/press-releases/')
                .setLocales([locale])
                .setCustomParam('process-macros', '1')
                .addSort('translation.published_at', 'desc')
                .setPaginate(page, PR_MAX_RELEASES_PEG_PAGE);

            if (filters.year) {
                queryBuilder.addMatchesAll('translation.published_at', 'like', `${filters.year}%`);
            }

            const request = queryBuilder.toObject();

            await store.dispatch('getEntity', { request });
        },
    },

    mutations: {
        setHttpStatus(state: Store<any>, args: { request: Storage, status: number }) {
            state[args.request.id] = state[args.request.id] || {};

            state[args.request.id].httpStatus = args.status;
            state[args.request.id].pagination = state[args.request.id].pagination || {};
            state[args.request.id].pagination.loading = false;
        },

        setEntity(state: Store<any>, args: { request: Storage, response: AxiosResponse }) {
            state[args.request.id] = state[args.request.id] || {};

            state[args.request.id].httpStatus = args.response.status;

            if (args.response.data?.pagination) {
                state[args.request.id].pagination = args.response.data.pagination;
                state[args.request.id].pagination.loading = false;
            }

            if (Array.isArray(args.response.data.data)) {
                state[args.request.id].items = args.request.addMore
                    ? [...state[args.request.id].items, ...args.response.data.data]
                    : args.response.data.data;
            } else {
                state[args.request.id].data = args.response.data.data;
            }
        },
    },
};
