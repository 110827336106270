import { StatusCodes } from 'http-status-codes';
// eslint-disable-next-line import/no-restricted-paths
import { ProductAPIQueryBuilder, Storage } from '@api/builders/product';
import { RC_MAX_ITEMS_PEG_SEARCH_PAGE, RC_ROOT_URL } from '@model/const/resource-center';
import { HttpRejection } from '@model/http/rejection';
import commonUtils from '@utils/common';
import { formatToLocaleDate } from '@utils/locales';

const QUERY_FILTER_PARAMS = ['types', 'products', 'tags', 'audiences', 'industries'];
const SEARCH_FILTER_PARAMS = ['products', 'tags', 'audiences'];

// TODO: this function is only imported once and should be instead inlined directly in the place where it's imported.
// Additionally, it breaks modules relationship importing '@api/builders/product' which is forbidden and enforced by linter.
// Because of this, the linter error enforcing such relationships is suppressed,
// and this actually should have been a fair warning about doing a wrong thing.
export function generateSearchQuery(searchData: any, locale: string): Storage {
    const currentPage = searchData.page || 1;

    const queryBuilder: ProductAPIQueryBuilder = new ProductAPIQueryBuilder('resources')
        .setEntityPath('/api/resources/resources/')
        .setCustomParam('process-macros', '1')
        .setLocales([locale])
        .setPaginate(currentPage, RC_MAX_ITEMS_PEG_SEARCH_PAGE);

    if (searchData.sort === 'popular') {
        queryBuilder.addSort('translation.views_count', 'desc');
    } else {
        queryBuilder.addSort('translation.published_at', 'desc');
    }

    const customParams = {};

    SEARCH_FILTER_PARAMS.forEach((param) => {
        if (searchData[param]?.length) {
            customParams[param] = searchData[param];
        }
    });

    if (Object.keys(customParams).length) {
        queryBuilder.setCustomParam('has', customParams);
    }

    if (searchData.types?.length) {
        queryBuilder.addMatchesAll('type_id', 'in', searchData.types);
    }

    if (searchData.industries?.length) {
        queryBuilder.addMatchesAll('industry_id', 'in', searchData.industries);
    }

    if (searchData.searchString) {
        queryBuilder.addMatchesAll('slug', 'like', `%${commonUtils.slugify(searchData.searchString)}%`);
    }

    return queryBuilder.toObject();
}

export function handleSearchQuery(rawQuery = {}) {
    const query = { ...rawQuery };
    QUERY_FILTER_PARAMS.forEach((i) => {
        if (typeof query[i] === 'string') {
            query[i] = [parseInt(query[i], 10)];
        } else if (Array.isArray(query[i])) {
            query[i] = query[i].map(Number);
        }
    });
    return query;
}

export function extendResourceData(resource, locale) {
    const fixedData = {
        type: {
            id: resource.type.id,
            title: resource.type.title,
            to: `${RC_ROOT_URL}/category/${resource.type.slug}/`,
        },
        to: `${RC_ROOT_URL}/resource/${resource.slug}/`,
        published_at: formatToLocaleDate(resource.published_at, locale),
    };

    return {
        ...resource,
        ...fixedData,
    };
}

export function handleSearchHashString(hashString = '') {
    const query = hashString.replace('#', '');
    const searchParams = new URLSearchParams(query);
    const params: any = {};

    searchParams.forEach((value, key) => {
        params[key] = !params[key] ? value : [...params[key], value];
    });

    if (!params.sort) {
        params.sort = 'recent';
    }

    const searchData: any = {
        page: parseInt(params.page, 10) || 1,
    };

    searchData.sort = params.sort;

    QUERY_FILTER_PARAMS.forEach((param) => {
        if (params[param]) {
            searchData[param] = typeof params[param] === 'string'
                ? [parseInt(params[param], 10)]
                : params[param].map(Number);
        }
    });

    if (params.searchString) {
        searchData.searchString = params.searchString;
    }

    return searchData;
}

export function assertLocaleIsAllowed(store: any, locale: string) {
    store.dispatch('locales/getLocaleData', { locale });
    const localeData = store.state.locales?.locale;
    if (!localeData?.is_active_on_resource_center) {
        throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);
    }
}
