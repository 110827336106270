import { RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ActionContext, Store } from 'vuex';
import getAPI6sense from '@api/6sense';
import getAPIService from '@api/service';
import { ServiceAPIError } from '@model/http/service-api-error';
import { Logger } from '@utils/logger';

export default {
    namespaced: true,

    state: () => ({
        senseData: {},
        loading: false,
    }),

    actions: {
        async getData(store: ActionContext<string, any>) {
            const client = getAPI6sense(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 60 * 1000,
                validateStatus: () => true,
                method: 'GET',
                url: '/v3/company/details',
                withCredentials: true,
            };

            try {
                const response = await client.request(cfg);
                store.commit('setData', response.data);
                await store.dispatch('logData', response);
            } catch (error) {
                Logger.error({ error });
                store.dispatch('setLoading', false);
                throw ServiceAPIError.FromAxiosResponse(error);
            }
        },

        async logData(store: never, payload: Record<string, any>) {
            const client = getAPIService(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 30 * 1000,
                validateStatus: () => true,
                method: 'POST',
                headers: {},
                url: '/svc/v1/debug/6sl',
                data: payload.data,
            };

            // loggedStatuses: !200

            const response = await client.request(cfg);

            if (response.status !== StatusCodes.NO_CONTENT) {
                Logger.error({ error: response });
                throw ServiceAPIError.FromAxiosResponse(response);
            }

            return response;
        },
    },

    mutations: {
        setData(state: Store<any>, payload: Record<string, any>) {
            state.senseData = payload;
        },
    },
};
