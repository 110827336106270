import { RawAxiosRequestConfig, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ActionContext, Store } from 'vuex';
import getAPIProduct from '@api/product';
import getAPIService from '@api/service';
import { ServiceAPIError } from '@model/http/service-api-error';
import { Logger } from '@utils/logger';

const getFilterFormDefaults = () => ({
    category: '',
    product: '',
    date: null,
    audience: '',
    language: '',
    sorting: 'recent',
});

export default {
    namespaced: true,

    state: () => {
        // WARN: empty state shape can cause reactivity issues on mutation
        const shape = () => ({
            id: '',
            httpStatus: 0,
            pagination: {},
            items: [],
        });

        return {
            country: {
                id: null,
                isConfirmed: false,
            },
            event: shape(),
            searchResults: shape(),
            banners: shape(),
            inPersonEvents: shape(),
            inPersonHybridEvents: shape(),
            virtualEvents: shape(),
            virtualHybridEvents: shape(),
            pastEvents: shape(),
            otherEvents: shape(),
            onDemandEvents: shape(),
            audiences: shape(),
            categories: shape(),
            videos: shape(),
            languages: shape(),
            filterForm: getFilterFormDefaults(),
            mainSiteEvents: shape(),
            highlightedEvent: shape(),
        };
    },

    actions: {
        setCountry({ commit }, location) {
            commit('setCountry', {
                countryId: location.countryId,
                isConfirmed: location.isConfirmed,
            });
        },

        setFilterForm({ commit }, data) {
            commit('setFilterForm', data);
        },

        resetFilterForm({ commit }) {
            commit('resetFilterForm');
        },

        async getEntity(store: ActionContext<any, any>, args: { request: Storage }) {
            store.commit('initEntityLoading', args);

            const request = args.request;

            const client = getAPIProduct(this.$context);

            const opts: RawAxiosRequestConfig = {
                validateStatus: (status: number) => status === StatusCodes.OK,
                timeout: process.env.VUE_ENV === 'client' ? 30000 : 6000,
                method: 'GET',
                url: request.entityPath,
                params: request.params,
            };

            try {
                const response = await client.request(opts);
                store.commit('setEntity', { request, response });
            } catch (error) {
                Logger.error({ error });
                store.commit('setHttpStatus', { request, status: error.status || StatusCodes.GATEWAY_TIMEOUT });
            }
        },

        async checkEmailInSpDomain(store: never, email: string) {
            const client = getAPIService(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 30 * 1000,
                validateStatus: () => true,
                method: 'GET',
                url: '/svc/v1/marketing/forms/leads/sp-email',
                params: { email },
            };

            const response = await client.request(cfg);

            if (response.status !== StatusCodes.OK) {
                Logger.error({ error: response });
                throw ServiceAPIError.FromAxiosResponse(response);
            }

            return response.data;
        },

        async checkEmailIn6senseList(store: never, email: string) {
            const client = getAPIService(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 30 * 1000,
                validateStatus: () => true,
                method: 'GET',
                url: 'https://6sense.deian.eu/validate/',
                params: { email },
            };

            const response = await client.request(cfg);

            if (response.status !== StatusCodes.OK) {
                Logger.error({ error: response });
                throw ServiceAPIError.FromAxiosResponse(response);
            }

            return response.data;
        },
    },

    mutations: {
        setCountry(state, location) {
            state.country.id = location.countryId;
            state.country.isConfirmed = location.isConfirmed;
        },

        setFilterForm(state, formData) {
            state.filterForm = formData;
        },

        resetFilterForm(state) {
            state.filterForm = getFilterFormDefaults();
        },

        initEntityLoading(state, args: { request: Storage }) {
            state[args.request.id] = state[args.request.id] || {};

            state[args.request.id].httpStatus = null;
            state[args.request.id].pagination = state[args.request.id].pagination || {};
            state[args.request.id].pagination.loading = true;
        },

        setHttpStatus(state: Store<any>, args: { request: Storage, status: number }) {
            state[args.request.id] = state[args.request.id] || {};

            state[args.request.id].id = args.request.id;
            state[args.request.id].httpStatus = args.status;
            state[args.request.id].pagination = state[args.request.id].pagination || {};
            state[args.request.id].pagination.loading = false;
        },

        setEntity(state: Store<any>, args: { request: Storage, response: AxiosResponse }) {
            state[args.request.id] = state[args.request.id] || {};

            state[args.request.id].id = args.request.id;
            state[args.request.id].httpStatus = args.response.status;
            state[args.request.id].pagination = args.response.data.pagination;
            state[args.request.id].pagination.loading = false;

            const items = args.request.addMore
                ? [...state[args.request.id].items, ...args.response.data.data]
                : args.response.data.data;

            state[args.request.id].items = items;
        },
    },
};
