import { RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import getAPIProduct from '@api/product';
import { convertToConsumableObjects } from '@utils/api-response';
import { Logger } from '@utils/logger';

const FIELDS_PRIVATE = [
    'created_at',
    'updated_at',
    'creator',
    'creator_id',
    'page_audiences',
    'page_types',
    'products',
];

export default {
    namespaced: true,

    state: () => ({
        data: [],
    }),

    actions: {
        async getAll(store, args) {
            const locale = args.locale;
            const slug = args.slug.replace(/\/$/, '');
            const siteID = args.siteID;

            const now = new Date().toISOString();

            const req = new ProductAPIQueryBuilder('ribbon')
                .setCustomParam('page', { locale, slug, website_id: siteID })
                .addMatchesAll('is_active', '=', '1')
                .addMatchesAll('is_active_since', '<=', now)
                .addMatchesAll('is_active_until', '>=', now)
                .addSort('created_at', 'desc')
                .addSort('id', 'desc');

            const options: RawAxiosRequestConfig = {
                method: 'GET',
                url: '/api/core/ribbons',
                timeout: 2000,
                validateStatus: (status: number) => status === StatusCodes.OK,
                params: req.toObject().params,
            };

            try {
                const response = await getAPIProduct(this.$context).request(options);

                // Convert response to consumable ribbons objects
                const ribbons = convertToConsumableObjects(response.data.data, FIELDS_PRIVATE);
                store.commit('setAll', ribbons);
            } catch (error) {
                Logger.error({ error });
            }
        },
    },

    mutations: {
        setAll(state, ribbons = []) {
            state.data = ribbons;
        },
    },
};
