import { RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import getAPIService from '@api/service';
import { Logger } from '@utils/logger';

interface LocatorRequest {
    type?: 'partners' | 'distributors';
    country?: string;
}

export default {
    namespaced: true,

    state: () => ({}),

    actions: {
        async getCountryList(store: never, locator: LocatorRequest = {}): Promise<any> {
            const client = getAPIService(this.$context);

            const options: RawAxiosRequestConfig = {
                timeout: 60 * 1000,
                validateStatus: (status: number) => status === StatusCodes.OK,
                method: 'GET',
                url: `/svc/v1/partners-portal/locator/${locator.type || 'partners'}/countries`,
            };

            try {
                const response = await client.request(options);
                return response.data;
            } catch (error) {
                Logger.error({ error });
                return null;
            }
        },

        async getCompanyList(store: never, locator: LocatorRequest = {}): Promise<any> {
            const client = getAPIService(this.$context);

            const options: RawAxiosRequestConfig = {
                timeout: 60 * 1000,
                validateStatus: (status: number) => status === StatusCodes.OK,
                method: 'GET',
                url: `/svc/v1/partners-portal/locator/${locator.type || 'partners'}/partners`,
                params: { country: locator.country || '' },
            };

            try {
                const response = await client.request(options);
                return response.data;
            } catch (error) {
                Logger.error({ error });
                return null;
            }
        },
    },
};
