import https from 'https';
import { AxiosInstance } from 'axios';

const getAgent = (ignoreSSLErrors: boolean): https.Agent | undefined => {
    if (!https.Agent) return undefined;

    return new https.Agent({
        rejectUnauthorized: !ignoreSSLErrors,
    });
};

export const AssignHttpsAgent = (axios: AxiosInstance, ignoreSSLErrors: boolean): AxiosInstance => {
    // eslint-disable-next-line no-param-reassign
    axios.defaults.httpsAgent = getAgent(ignoreSSLErrors);
    return axios;
};
