import axios, { AxiosInstance, RawAxiosRequestConfig } from 'axios';
import { flow } from 'lodash';
import qs from 'qs';
import { AssignHttpsAgent } from './plugins/https-agent';
import { ObservePromAPILatency } from './plugins/prom-api-latency';
import { WrapAxiosWithRequestLogger } from './plugins/request-logger';

const getAPIProduct = (context): AxiosInstance => {
    const defaults: RawAxiosRequestConfig = {
        validateStatus: () => true,
        timeout: 10000,
        paramsSerializer: {
            serialize: (params) => qs.stringify(params, { encodeValuesOnly: true }),
        },
        maxRedirects: 0,
    };

    const baseURL = process.env.VUE_ENV === 'server'
        ? context.public.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_PRODUCT_API_SERVER
        : context.public.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_PRODUCT_API_CLIENT;

    const instance = axios.create({
        ...defaults,
        baseURL,
    });

    return flow(
        (it: AxiosInstance) => AssignHttpsAgent(it, context.env?.HEAD_SITE_MAIN_FEATURE_CLIENT_IGNORE_SSL_ERRORS === 'true'),
        (it: AxiosInstance) => WrapAxiosWithRequestLogger(it),
        (it: AxiosInstance) => ObservePromAPILatency(it),
    )(instance);
};

export default getAPIProduct;
