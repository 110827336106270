export class HttpRejection extends Error {
    public httpStatusCode: number;

    public httpHeaders: Record<string, string>[] = [];

    constructor(message: string, code: number) {
        super(message);
        // Fix prototype chain (see https://stackoverflow.com/a/48342359/1242944)
        Object.setPrototypeOf(this, new.target.prototype);

        this.httpStatusCode = code;
    }

    public addHeader(key: string, val: string): void {
        this.httpHeaders.push({ key, val });
    }
}
