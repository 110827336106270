import { StatusCodes } from 'http-status-codes';
import getAPIService from '@api/service';
import { Logger } from '@utils/logger';

export default {
    namespaced: true,

    state: () => ({}),

    actions: {
        async get(store: never, data: Record<string, any>) {
            try {
                const client = getAPIService(this.$context);

                const options = {
                    method: 'POST',
                    url: '/svc/v1/cleverbridge/surl',
                    timeout: 30000,
                    validateStatus: (status: number) => status === StatusCodes.OK,
                    data,
                    withCredentials: true,
                };

                const response = await client.request(options);
                return response;
            } catch (error) {
                Logger.error({ error });
                return null;
            }
        },
    },
};
