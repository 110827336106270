import { AxiosInstance, AxiosResponse } from 'axios';
import { Summary } from 'prom-client';
import { getMetric } from '@utils/server-prom-client';

export const ObservePromAPILatency = (axios: AxiosInstance): AxiosInstance => {
    const summary = getMetric<Summary>('api_request_latency');
    if (!summary) {
        return axios;
    }

    const startedAt = Date.now();

    const observeFn = (resp: AxiosResponse): AxiosResponse => {
        try {
            const url = new URL(resp.config.url, resp.config.baseURL || 'https://example.com');
            const elapsedMs = Date.now() - startedAt;
            summary.labels(url.hostname, url.pathname).observe(elapsedMs);
        } catch {
            // NO OP
        }

        return resp;
    };

    axios.interceptors.response.use(observeFn);

    return axios;
};
