import prom, { collectDefaultMetrics } from 'prom-client';

const METRIC_NAME_PREFIX = 'head_site_front';

export const init = () => {
    collectDefaultMetrics({ prefix: `${METRIC_NAME_PREFIX}_` });

    /* eslint-disable no-new */
    new prom.Counter({
        name: `${METRIC_NAME_PREFIX}_http_request_count`,
        help: `${METRIC_NAME_PREFIX}_http_request_count`,
        labelNames: ['hostname', 'status_code'],
    });

    new prom.Summary({
        name: `${METRIC_NAME_PREFIX}_api_request_latency`,
        help: `${METRIC_NAME_PREFIX}_api_request_latency summary (ms)`,
        labelNames: ['hostname', 'pathname'],
        percentiles: [0.5, 0.9, 0.99],
    });
    /* eslint-enable */
};

export function getMetric<T>(unprefixedName: string): T {
    return prom?.register?.getSingleMetric(`${METRIC_NAME_PREFIX}_${unprefixedName}`) as T;
}
