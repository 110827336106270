export default {
    namespaced: true,

    state: () => ({
        integrations: [],
        integration: {},
        categories: [],
    }),

    actions: {
        getIntegrations(store) {
            const integrations = this.$context.Synced.solutionsIntegrations.getAll();
            store.commit('setIntegrations', integrations);
        },

        getIntegration(store, args) {
            const { slug } = args;
            const integration = this.$context.Synced.solutionsIntegrations.query(slug);
            store.commit('setIntegration', integration);
        },

        getCategories(store) {
            const categories = this.$context.Synced.solutionsCategories.getAll();
            store.commit('setCategories', categories);
        },
    },

    mutations: {
        setIntegrations(state, integrations) {
            state.integrations = integrations;
        },

        setIntegration(state, integration) {
            state.integration = integration;
        },

        setCategories(state, categories) {
            state.categories = categories;
        },
    },
};
