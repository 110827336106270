// here is the reference to check a date-time format for any locale
// https://leap.hcldoc.com/help/topic/SSS28S_8.2.1/XFDL_Specification/i_xfdl_r_locale_quick_reference.html
import { format as formatCustomDate, parseISO as parseISODate } from 'date-fns';
import {
    de,
    enGB,
    enUS,
    es,
    fr,
    id,
    ja,
    ko,
    pl,
    zhCN,
    zhTW,
    he,
    it,
    cs,
    hu,
    nl,
    sv,
    ptBR,
    ro,
    tr,
    bg,
    hi,
    th,
    vi,
// eslint-disable-next-line import/extensions
} from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';

type LinkOptions = {
    localeList: string[]
    localesConfig: { isoCode: string, language: string }[]
    urlTemplate: string
}

const LOCALE_DEFAULT = 'en-us';

const LOCALES_TIME_FORMAT_MAP = {
    'en-eu': { locale: enUS, format: 'dd MMMM yyyy' },
    'en-gb': { locale: enGB, format: 'dd MMMM yyyy' },
    'en-sg': { locale: enUS, format: 'MMMM dd, yyyy' },
    'en-us': { locale: enUS, format: 'MMMM dd, yyyy' },
    'id-id': { locale: id, format: 'dd MMMM yyyy' },
    'pl-pl': { locale: pl, format: 'dd MMMM yyyy' },
    'de-de': { locale: de, format: 'dd. MMMM yyyy' },
    'es-es': { locale: es, format: "dd 'de' MMMM 'de' yyyy" },
    'es-mx': { locale: es, format: "dd 'de' MMMM 'de' yyyy" },
    'fr-fr': { locale: fr, format: 'dd MMMM yyyy' },
    'ja-jp': { locale: ja, format: 'yyyy年M月d日' },
    'ko-kr': { locale: ko, format: 'PPP' },
    'zh-cn': { locale: zhCN, format: 'yyyy年M月d日' },
    'zh-tw': { locale: zhTW, format: 'yyyy年M月d日' },
    'he-il': { locale: he, format: 'd MMMM yyyy' },
    'it-it': { locale: it, format: 'dd MMMM yyyy' },
    'cs-cz': { locale: cs, format: 'd. MMMM yyyy' },
    'hu-hu': { locale: hu, format: 'yyyy. MMMM d.' },
    'nl-nl': { locale: nl, format: 'd MMMM yyyy' },
    'sv-se': { locale: sv, format: 'd MMMM yyyy' },
    'pt-br': { locale: ptBR, format: "d 'de' MMMM 'de' yyyy" },
    'ro-ro': { locale: ro, format: 'd MMMM yyyy' },
    'tr-tr': { locale: tr, format: 'd MMMM yyyy' },
    'bg-bg': { locale: bg, format: 'dd MMMM yyyy' },
    'hi-in': { locale: hi, format: 'dd MMMM yyyy' },
    'th-th': { locale: th, format: 'dd MMMM yyyy' },
    'vi-vn': { locale: vi, format: 'MMMM \'năm\' y' },
};

export const LOCALE_TO_ENGLISH_MAP = {
    'bg-bg': 'en-eu',
    'cs-cz': 'en-eu',
    'de-de': 'en-eu',
    'en-eu': 'en-eu',
    'en-gb': 'en-gb',
    'en-sg': 'en-sg',
    'en-us': 'en-us',
    'es-es': 'en-eu',
    'es-mx': 'en-us',
    'fr-fr': 'en-eu',
    'he-il': 'en-eu',
    'hi-in': 'en-sg',
    'hu-hu': 'en-eu',
    'id-id': 'en-sg',
    'it-it': 'en-eu',
    'ja-jp': 'en-sg',
    'ko-kr': 'en-sg',
    'nl-nl': 'en-eu',
    'pl-pl': 'en-eu',
    'pt-br': 'en-us',
    'ro-ro': 'en-eu',
    'sv-se': 'en-eu',
    'th-th': 'en-eu',
    'tr-tr': 'en-eu',
    'vi-vn': 'en-sg',
    'zh-cn': 'en-sg',
    'zh-tw': 'en-sg',
};

export const LOCALE_TO_COUNTRY_MAP = {
    'de-de': 'Deutschland',
    'en-au': 'Australia',
    'en-eu': 'Europe',
    'en-gb': 'United Kingdom',
    'en-hk': 'Hong Kong',
    'en-in': 'India',
    'en-sg': 'Singapore',
    'en-us': 'United States',
    'es-es': 'España / Spain',
    'es-mx': 'México / Mexico',
    'fr-ca': 'Canada Français',
    'fr-fr': 'France',
    'id-id': 'Indonesian',
    'it-it': 'Italia / Italy',
    'ja-jp': '日本 / Japan',
    'ko-kr': '대한민국 / Korea',
    'pl-pl': 'Polska / Poland',
    'zh-cn': '中国 / China',
    'zh-tw': '台灣 / Taiwan',
    'bg-bg': 'Bulgaria',
    'cs-cz': 'Czech',
    'he-il': 'Israel',
    'hu-hu': 'Hungary',
    'hi-in': 'India',
    'pt-br': 'Brazil',
    'ro-ro': 'Romania',
    'tr-tr': 'Turkey',
    'vi-vn': 'Vietnam',
    'nl-nl': 'Netherlands',
    'th-th': 'Thailand',
    'sv-se': 'Sweden',
};

export const LOCALE_TO_LANGUAGE_MAP = {
    'en-us': 'English (US & Canada)',
    'en-eu': 'English (EU)',
    'en-gb': 'English (UK)',
    'en-sg': 'English (Singapore)',
    'id-id': 'Bahasa Indonesia',
    'pl-pl': 'Polski',
    'de-de': 'Deutsch',
    'fr-fr': 'Français',
    'it-it': 'Italiano',
    'he-il': 'עברית',
    'es-es': 'Español (Spain)',
    'es-mx': 'Español (Mexico)',
    'bg-bg': 'Български',
    'cs-cz': 'Čeština',
    'hu-hu': 'Magyar',
    'nl-nl': 'Dutch',
    'ja-jp': '日本語',
    'zh-cn': '简体中文',
    'zh-tw': '繁體中文',
    'ko-kr': '한국어',
    'hi-in': 'हिन्दी',
    'pt-br': 'Português (Brazil)',
    'ro-ro': 'Română',
    'tr-tr': 'Türkçe',
    'vi-vn': 'Tiếng Việt',
    'sv-se': 'Svenska',
};

/**
 * Retrieves the available locales excluding the current locale and 'es-mx'.
 *
 * @param list - The list of locales to filter.
 * @param currentLocale - The current locale for comparison.
 * @returns An array of available locales excluding the current locale and 'es-mx'.
 */
export function getAvailableLocales(list: { locale: string }[], currentLocale: string): string[] {
    const localeList = list?.map((item) => item.locale) || [];
    const filteredLocales = localeList.filter((locale) => !locale.startsWith('en') && locale !== 'es-mx');
    const currentEnglishLocale = localeList.find((locale) => locale === LOCALE_TO_ENGLISH_MAP[currentLocale]);

    return [currentEnglishLocale, ...filteredLocales]
        .filter(Boolean)
        .filter((locale) => locale !== currentLocale);
}

/**
 * Formats a given timestamp to a locale-specific date string.
 * If the timestamp is falsy, returns null.
 *
 * @param timestamp - The timestamp to format.
 * @param locale - The locale to use for formatting.
 * @returns The formatted date string, or null if the timestamp is falsy.
 */
export function formatToLocaleDate(timestamp: string, locale: string): string | null {
    if (!timestamp) return null;

    const localeOptions = LOCALES_TIME_FORMAT_MAP[locale] || LOCALES_TIME_FORMAT_MAP[LOCALE_DEFAULT];
    const dateObject = parseISODate(timestamp);

    return formatCustomDate(dateObject, localeOptions.format, { locale: localeOptions.locale });
}

export function getLinksToAvailableLocales(options: LinkOptions) {
    const { localeList, localesConfig, urlTemplate } = options;

    return localeList
        .map((locale) => localesConfig.find((config) => config.isoCode === locale))
        .filter(Boolean)
        .map((config) => {
            const to = urlTemplate.replace('{{LOCALE}}', config.isoCode);
            let text = config.language;

            // specify the spanish locale
            if (['es-es', 'es-mx'].includes(config.isoCode)) {
                text = `${text} (${config.isoCode.split('-').pop().toUpperCase()})`;
            }

            return { to, text, locale: config.isoCode };
        });
}

export function formatUsingTimeZone(utcDate: Date, timezone: string, locale: string) {
    const localeOptions = LOCALES_TIME_FORMAT_MAP[locale] || LOCALES_TIME_FORMAT_MAP[LOCALE_DEFAULT];
    return formatInTimeZone(utcDate, timezone, localeOptions.format, {
        locale: localeOptions.locale,
    });
}
