import { AxiosResponse, RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ActionContext, Store } from 'vuex';
import getAPIProduct from '@api/product';
import { HttpRejection } from '@model/http/rejection';
import { Logger } from '@utils/logger';

export default {
    namespaced: true,

    state: () => ({
        page: {},
    }),

    actions: {
        async getEntity(store: ActionContext<any, any>, args: { request: Storage, httpRejectEmptyState: Boolean }) {
            const request = args.request;
            const client = getAPIProduct(this.$context);

            const opts: RawAxiosRequestConfig = {
                validateStatus: (status: number) => status === StatusCodes.OK,
                timeout: process.env.VUE_ENV === 'client' ? 30000 : 6000,
                method: 'GET',
                url: request.entityPath,
                params: request.params,
            };

            try {
                const response = await client.request(opts);
                store.commit('setEntity', { request, response });
            } catch (error) {
                Logger.error({ error });
                const httpStatus = error.status || StatusCodes.GATEWAY_TIMEOUT;
                const message = error.message || 'Premature rendering stop';
                throw new HttpRejection(message, httpStatus);
            }
        },
    },

    mutations: {
        setEntity(state: Store<any>, args: { request: Storage, response: AxiosResponse }) {
            state[args.request.id] = args.response.data.data[0];
        },
    },
};
