import axios, { RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ActionContext, Store } from 'vuex';
import { Logger } from '@utils/logger';

function parseJobs(data: Record<string, any> = {}) {
    const locations = {};

    const storeJob = (job, departmentName) => {
        let country = job.location.name.split(',').pop().trim();

        if (country.length < 3) {
            country = 'United States';
        }

        if (!locations[country]) {
            locations[country] = {};
        }

        if (!locations[country][departmentName]) {
            locations[country][departmentName] = [];
        }

        const handledJob = {
            date: job.updated_at,
            title: job.title,
            to: job.absolute_url,
            location: job.location.name,
        };

        locations[country][departmentName].push(handledJob);
    };

    const handleDepartment = (department, departmentName = '') => {
        if (department.children && department.children.length) {
            department.children.forEach((child) => {
                handleDepartment(child, department.name);
            });
        }

        department.jobs.forEach((job) => {
            const depName = Number.isInteger(departmentName) ? department.name : departmentName;
            storeJob(job, depName);
        });
    };

    if (data.departments && data.departments.length) {
        data.departments.forEach(handleDepartment);
    }

    return locations;
}

export default {
    namespaced: true,

    state: () => ({
        data: {},
    }),

    actions: {
        async getAll(store: ActionContext<any, any>) {
            try {
                const client = axios.create();

                const cfg: RawAxiosRequestConfig = {
                    timeout: 30000,
                    validateStatus: (status: number) => status === StatusCodes.OK,
                    maxRedirects: 0,
                    method: 'GET',
                    url: 'https://api.greenhouse.io/v1/boards/acronis/departments?render_as=tree',
                };

                const response = await client.request(cfg);
                const jobs = parseJobs(response.data);
                store.commit('setAll', jobs);
            } catch (error) {
                Logger.error({ error });
            }
        },
    },

    mutations: {
        setAll(state: Store<any>, jobs: Record<string, any>) {
            // @ts-ignore
            state.data = jobs;
        },
    },
};
