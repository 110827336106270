import { AxiosResponse, RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ActionContext, Store } from 'vuex';
import { Storage } from '@api/builders/product';
import getAPIProduct from '@api/product';
import { getAvailableLocales } from '@utils/locales';
import { Logger } from '@utils/logger';
import { generateSearchQuery } from '@utils/resource-center';

export default {
    namespaced: true,

    state: () => {
        const shape = () => ({
            id: '',
            httpStatus: 0,
            pagination: {},
            items: [],
            data: null,
        });

        return {
            resource: shape(),
            resources: shape(),
            tags: shape(),
            types: shape(),
            audiences: shape(),
            industries: shape(),
            banners: shape(),
            cta: shape(),
            ctaData: shape(),
            locales: shape(),
            featuredResources: shape(),
            caseStudyResources: shape(),
            analystReportsResources: shape(),
            availableLocales: shape(),
        };
    },

    getters: {
        getAvailableLocales: (state) => (currentLocale) => getAvailableLocales(state.availableLocales.items, currentLocale),
    },

    actions: {
        async getEntity(store: ActionContext<any, any>, args: { request: Storage }) {
            const request = args.request;

            const client = getAPIProduct(this.$context);

            const opts: RawAxiosRequestConfig = {
                validateStatus: (status: number) => status === StatusCodes.OK,
                timeout: process.env.VUE_ENV === 'client' ? 30000 : 6000,
                method: 'GET',
                url: request.entityPath,
                params: request.params,
            };

            try {
                const response = await client.request(opts);
                store.commit('setEntity', { request, response });
            } catch (error) {
                Logger.error({ error });
                store.commit('setHttpStatus', { request, status: error.status || StatusCodes.GATEWAY_TIMEOUT });
            }
        },

        async applySearchData(store: ActionContext<any, any>, { searchData, locale }) {
            const request: Storage = generateSearchQuery(searchData, locale);
            await store.dispatch('getEntity', { request });
        },

        async sendViewBeacon(_: never, args: { request: Storage }) {
            const request = args.request;
            const client = getAPIProduct(this.$context);

            const opts: RawAxiosRequestConfig = {
                validateStatus: (status: number) => status === StatusCodes.CREATED,
                timeout: 30000,
                method: 'POST',
                url: request.entityPath,
                params: request.params,
            };

            // This is a fire-n-forget action
            try {
                await client.request(opts);
            } catch (error) {
                Logger.error({ error });
            }
        },
    },

    mutations: {
        setHttpStatus(state: Store<any>, args: { request: Storage, status: number }) {
            state[args.request.id] = state[args.request.id] || {};

            state[args.request.id].id = args.request.id;
            state[args.request.id].httpStatus = args.status;
            state[args.request.id].pagination = state[args.request.id].pagination || {};
            state[args.request.id].pagination.loading = false;
        },

        setEntity(state: Store<any>, args: { request: Storage, response: AxiosResponse }) {
            state[args.request.id] = state[args.request.id] || {};

            state[args.request.id].id = args.request.id;
            state[args.request.id].httpStatus = args.response.status;

            if (args.response.data?.pagination) {
                state[args.request.id].pagination = args.response.data.pagination;
                state[args.request.id].pagination.loading = false;
            }

            if (Array.isArray(args.response.data.data)) {
                state[args.request.id].items = args.request.addMore
                    ? [...state[args.request.id].items, ...args.response.data.data]
                    : args.response.data.data;
            } else {
                state[args.request.id].data = args.response.data.data;
            }
        },
    },
};
