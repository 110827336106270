// Explanation on web-vitals abbreviations https://web.dev/vitals/
// NB: this script is client-only
import { onFID, onLCP, onFCP, onTTFB } from 'web-vitals';
import { Logger } from './logger';

export function sendBrowserMetrics(context: any) {
    const watchDog = new Set();

    const namesMap = {
        FID: 'first-input-delay',
        LCP: 'largest-contentful-paint',
        FCP: 'first-contentful-paint',
        TTFB: 'time-to-first-byte',
    };

    const reportOnce = ({ name, value }) => {
        if (!watchDog.has(name)) {
            watchDog.add(name);
        } else {
            return;
        }

        const readableName = namesMap[name] || name;
        const endpoint = `${context.public.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_SERVICE_API_CLIENT}/svc/v1/metrics/latency/${readableName}`;
        const payload = JSON.stringify({ latency: value });
        const opts = { type: 'application/json' };
        const blob = new Blob([payload], opts);

        const sendBeacon = navigator.sendBeacon.bind(navigator);
        sendBeacon(endpoint, blob);
    };

    try {
        (window.metrics || []).forEach(reportOnce);

        onFID(reportOnce);
        onLCP(reportOnce);
        onFCP(reportOnce);
        onTTFB(reportOnce);
    } catch (error) {
        Logger.error({ error });
    }
}
