import { RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import getAPIAccount from '@api/account';
import getAPIService from '@api/service';
import { ServiceAPIError } from '@model/http/service-api-error';
import formUtils from '@utils/form.js';
import { Logger } from '@utils/logger';

// TODO: for some reason every action here checks some particular HTTP statuses and log them. Why?
// Shouldn't we just put them to `validateStatus`?
// TODO: there's no timeouts handling in this module, because axios will THROW a real error in case of timeout exceeded

interface RecaptchaGuarded {
    _recaptchaResponse?: string;
}

type SubscribeRequest = Record<string, any> & RecaptchaGuarded;

export default {
    namespaced: true,

    state: () => ({
        queryStringData: {},
    }),

    actions: {
        async sendLead(store: never, request: SubscribeRequest) {
            const { _recaptchaResponse, actionURL, ...data } = request;

            // WEB-44946
            data.uit = formUtils.getSecondsOnPage();

            const client = getAPIService(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 60 * 1000,
                validateStatus: () => true,
                method: 'POST',
                headers: {},
                url: actionURL || '/svc/v1/marketing/forms/leads',
                data,
            };

            if (_recaptchaResponse) {
                cfg.headers['X-GRecaptcha-Response'] = _recaptchaResponse;
            }

            const response = await client.request(cfg);

            if (response.status !== StatusCodes.OK) {
                Logger.error({ error: response });
                throw ServiceAPIError.FromAxiosResponse(response);
            }

            return response.data;
        },

        async accountRegister(store: never, data: Record<string, any>) {
            const client = getAPIAccount(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 60 * 1000,
                validateStatus: () => true,
                method: 'POST',
                url: '/v2/auth/register',
                data,
            };

            // loggedStatuses: [200, 422]

            const response = await client.request(cfg);
            return response;
        },

        async accountLogin(store: never, data: Record<string, any>) {
            const client = getAPIAccount(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 60 * 1000,
                validateStatus: () => true,
                method: 'POST',
                url: '/v2/auth/login',
                withCredentials: true,
                data,
            };

            // loggedStatuses: [200, 401]

            const response = await client.request(cfg);
            return response;
        },

        async accountUpdate(store: never, data: Record<string, any>) {
            const client = getAPIAccount(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 60 * 1000,
                validateStatus: () => true,
                method: 'PUT',
                url: '/v2/account',
                withCredentials: true,
                data,
            };

            // loggedStatuses: !200

            const response = await client.request(cfg);
            return response;
        },

        async accountAgreement(store: never, data: Record<string, any>) {
            const client = getAPIAccount(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 60 * 1000,
                validateStatus: () => true,
                method: 'PUT',
                url: '/v2/account/agreement',
                withCredentials: true,
                data,
            };

            // loggedStatuses: !200

            const response = await client.request(cfg);
            return response;
        },

        async accountTrial(store: never, data: Record<string, any>) {
            const client = getAPIAccount(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 60 * 1000,
                validateStatus: () => true,
                method: 'POST',
                url: '/v2/products/trial',
                withCredentials: true,
                data,
            };

            // loggedStatuses: !200

            const response = await client.request(cfg);
            return response;
        },

        async getOptInStatus(store: never, email: string) {
            const client = getAPIService(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 30 * 1000,
                validateStatus: () => true,
                method: 'GET',
                url: '/svc/v1/marketing/forms/leads/opt-in',
                params: {
                    email,
                },
            };

            const response = await client.request(cfg);

            if (response.status !== StatusCodes.OK) {
                Logger.error({ error: response });
                throw ServiceAPIError.FromAxiosResponse(response);
            }

            return response.data;
        },
    },

    mutations: {
        SET_QUERY_STRING_DATA(state: any, queryStringData: {}) {
            state.queryStringData = queryStringData;
        },
    },
};
