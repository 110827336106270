import { AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';

export class ServiceAPIError extends Error {
    public httpStatus: StatusCodes;

    public code: string;

    public errors: Record<string, any>[];

    public ex?: Record<string, any>;

    public static FromAxiosResponse(response: AxiosResponse): ServiceAPIError {
        const error = new ServiceAPIError();
        error.httpStatus = response.status || StatusCodes.INTERNAL_SERVER_ERROR;
        error.code = response.data?.code || 'unknown';
        error.errors = response.data?.errors || [];
        error.ex = response.data.ex;
        return error;
    }
}
