import { RawAxiosRequestConfig, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import getAPIService from '@api/service';
import { Logger } from '@utils/logger';

export default {
    namespaced: true,

    state: () => ({}),

    actions: {
        async board(store: never, boardID: string): Promise<AxiosResponse> {
            const client = getAPIService(this.$context);

            const cfg: RawAxiosRequestConfig = {
                timeout: 5000,
                validateStatus: (status: number) => status === StatusCodes.OK,
                method: 'GET',
                url: `/svc/v1/slapfive/board/${boardID}`,
            };

            try {
                return await client.request(cfg);
            } catch (error) {
                Logger.error({ error });
                return null;
            }
        },
    },
};
