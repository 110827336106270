export default {
    namespaced: true,

    state: () => ({}),

    getters: {
        $config(_state: never, _getters: never, root: any) {
            return root.context.public;
        },
    },
};
