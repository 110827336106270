import { ActionContext } from 'vuex';

export default {
    namespaced: true,

    state: () => ({
        locale: {},
        locales: [],
    }),

    actions: {
        getLocaleData(store: ActionContext<any, any>, args: { locale: string }) {
            const locale = args.locale;
            const localeData = this.$context.Synced.locales.query(locale);
            store.commit('setLocale', localeData);
        },

        getAll(store: ActionContext<any, any>) {
            const res = this.$context.Synced.locales.getAll();
            store.commit('setLocales', res);
        },
    },

    mutations: {
        setLocale(state: any, locale: Record<string, any>) {
            state.locale = locale;
        },

        setLocales(state: any, locales: any) {
            state.locales = locales;
        },
    },
};
