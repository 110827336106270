export default {
    namespaced: true,

    state: () => ({
        items: {},
    }),

    actions: {
        getAward(store, id) {
            const aid = id.toString();
            const item = this.$context.Synced.awards.query(aid);
            store.commit('setAward', { id, item });
        },
    },

    mutations: {
        setAward(state, { id, item }) {
            state.items[id] = item;
        },
    },
};
