import { RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ActionContext } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import getAPIProduct from '@api/product';
import { Logger } from '@utils/logger';

export default {
    namespaced: true,

    state: () => ({
        data: [],
    }),

    actions: {
        async getList(store: ActionContext<any, any>, args: any) {
            const { cleverbridgeIDs, coupon, currency, locale, paginate } = args;

            if (!cleverbridgeIDs.filter(Number).length) return;

            const payload = new ProductAPIQueryBuilder('licenses')
                .addMatchesAll('locale', '=', locale)
                .addMatchesAll('coupon', coupon ? '=' : 'null', coupon || 'null')
                .addMatchesAll('currency', '=', currency)
                .addMatchesAll('cleverbridge_id', 'in', cleverbridgeIDs)
                .setCustomParam('process-macros', '1')
                .setPaginate(paginate.number, paginate.size)
                .toObject();

            const options: RawAxiosRequestConfig = {
                method: 'GET',
                params: payload.params,
                url: '/api/core/licenses',
                validateStatus: (status: number) => status === StatusCodes.OK,
            };

            try {
                const licenses = await getAPIProduct(this.$context).request(options);
                store.commit('setAll', licenses.data.data);
            } catch (error) {
                Logger.error({ error });
            }
        },
    },

    mutations: {
        setAll(state, licenses = []) {
            state.data = [...state.data, ...licenses];
        },
    },
};
